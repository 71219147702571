import "./App.css";
import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const HomePage = lazy(() => import("./Screen/HomePage/HomePage"));
const Products = lazy(() => import("./Screen/Products/Products"));
const IndividualProduct = lazy(() => import("./Screen/Products/IndividualProduct/IndividualProduct"));
const Events = lazy(() => import("./Screen/Newsroom/Events"));
const Blog = lazy(() => import("./Screen/Blogs/BlogsPage"));
const BlogPage = lazy(() => import("./Screen/Blogs/Blog/BlogPage"))
const AboutUs = lazy(() => import("./Screen/AboutUs/Aboutus"));
const SelfHelpAfrica = lazy(() => import("./Screen/Showcase/ShowcaseDetails/selfhelpafrica"));
const CalibCertForm= lazy(() => import("./Screen/calibrationcert/CalibCertForm"))
function App() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/:category" element={<Products />} />
          <Route
            path="/products/:category/:productId"
            element={<IndividualProduct />}
          />
          <Route path="/events" element={<Events />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/blogs" element={<Blog/>} />
          <Route path="/blog/:slug" element={<BlogPage/>} />
          <Route path="/selfhelpafrica" element={<SelfHelpAfrica/>} />
          <Route path="/calibrationApplicationForm" element={<CalibCertForm/>} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
